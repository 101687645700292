<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackbarColor"
    >
    {{ snackbarText }}
    </v-snackbar>
    <v-row
      justify="center"
    >
      <v-spacer />
      <v-col :cols="$isMobile()? 12 : 8">
        <v-card elevation="10">
          <v-card-title>Statuto e Regolamenti</v-card-title>
          <v-card-text>
            <v-row
              v-for="(stat, i) in statuto"
              :key="i"
            >
              <v-btn
                icon
                small
                @click="statuto.splice(i, 1)"
              >
                <v-icon> mdi-trash-can-outline </v-icon>
              </v-btn>
              {{ stat.filename }}
            </v-row>
            <br>
            <v-file-input
              ref="documentUpload"
              label="Carica documento"
              @change="uploadDocument"
            />
          </v-card-text>
          <v-card-actions>
            <v-row
              justify="end"
            >
              <v-spacer />
              <v-col cols="2">
                <v-btn
                  dark
                  @click="saveSetting"
                >
                SALVA
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'PresentationSettings',
    data: () => ({
      statuto: [],
      backendURL: config.backendURL,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
    }),
    created: function () {
      this.reloadSettings()
    },
    methods: {
      uploadDocument (file) {
        if (file === undefined) {
          return
        }
        var component = this
        var formData = new FormData()
        formData.append('file', file)
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .post(config.backendURL + '/documents', formData, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.statuto.push({ document: response.data.document.id, filename: response.data.document.realfilename })
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      saveSetting () {
        var currentJWT = localStorage.getItem(config.authToken)
        var component = this
        axios
          .put(config.backendURL + '/settings/statuto', component.statuto, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Statuto salvato correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      reloadSettings () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .get(config.backendURL + '/settings/statuto', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              component.statuto = response.data.setting
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
    },
  }
</script>
