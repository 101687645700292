<template>
  <v-container>
    <BannerSettings />
    <StatutoSettings />
    <PresentationSettings />
    <TrasparenzaSettings />
    <AffiliazioniSettings />
    <DocumentazioneSettings />
  </v-container>
</template>
<script>
  import BannerSettings from './BannerSettings.vue'
  import StatutoSettings from './StatutoSettings.vue'
  import PresentationSettings from './PresentationSettings.vue'
  import TrasparenzaSettings from './TrasparenzaSettings.vue'
  import AffiliazioniSettings from './AffiliazioniSettings.vue'
  import DocumentazioneSettings from './DocumentazioneSettings.vue'
  export default {
    name: 'SiteSettings',
    components: { BannerSettings, PresentationSettings, StatutoSettings, TrasparenzaSettings, AffiliazioniSettings, DocumentazioneSettings },
    data: () => ({

    }),
    computed: {
    },
  }
</script>
