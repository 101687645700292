<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackbarColor"
    >
    {{ snackbarText }}
    </v-snackbar>
    <v-row
      justify="center"
    >
      <v-spacer />
      <v-col :cols="$isMobile()? 12 : 8">
        <v-card elevation="10">
          <v-card-title>Immagine banner</v-card-title>
          <v-card-text>
            <v-form @submit="onSubmit">
              <v-radio-group
                v-model="bannerimages.type"
                row
              >
                <v-radio
                  v-for="(type, n) in bannerTypes"
                  :key="n"
                  :label="type"
                  :value="type"
                />
              </v-radio-group>
            </v-form>
          </v-card-text>
          <v-card-text v-if="bannerimages.type == 'fixed'">
            <v-radio-group v-model="bannerimages.selected">
              <v-radio
                v-for="(image, i) in bannerimages.images"
                ref="radio"
                :key="i"
                :value="image"
              >
                <template v-slot:label>
                  <v-row align="start">
                    <v-col align-self="start">
                      <v-img
                        max-width="80"
                        :src="backendURL + '/images/' + image"
                        @mousedown="$event.preventDefault()"
                      />
                    </v-col>
                    <v-col>
                      <v-btn
                        icon
                        @click.native.stop.prevent="deleteImage(image, 0)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-text v-else>
            <v-checkbox
              v-for="(image, j) in bannerimages.images"
              :key="j"
              v-model="bannerimages.rotationimages"
              :value="image"
            >
              <template v-slot:label>
                <v-row align="start">
                  <v-col align-self="start">
                    <v-img
                      max-width="80"
                      :src="backendURL + '/images/' + image"
                      @mousedown="$event.preventDefault()"
                    />
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      @click.native.stop.prevent="deleteImage(image, 1)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-text>
            <v-file-input
              ref="imageUpload"
              accept="image/*"
              label="Aggiungi immagine"
              @change="uploadImage"
            />
          </v-card-text>
          <v-card-actions>
            <v-row
              justify="end"
            >
              <v-spacer />
              <v-col cols="2">
                <v-btn
                  dark
                  @click="saveSetting"
                >
                SALVA
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'BannerSettings',
    data: () => ({
      bannerimages: {
        images: [],
        selected: null,
        rotationimages: [],
        type: 'fixed',
      },
      backendURL: config.backendURL,
      bannerTypes: ['fixed', 'rotation'],
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
    }),
    created: function () {
      this.reloadSettings()
    },
    methods: {
      deleteImage (image, event) {
        if (this.bannerimages.rotationimages.includes(image)) {
          this.bannerimages.rotationimages.splice(this.bannerimages.rotationimages.indexOf(image), 1)
        }
        if (this.bannerimages.selected === image) {
          this.bannerimages.selected = null
        }
        if (this.bannerimages.images.includes(image)) {
          this.bannerimages.images.splice(this.bannerimages.images.indexOf(image), 1)
        }
        console.log(this.bannerimages)
      },
      uploadImage (file) {
        var component = this
        var formData = new FormData()
        formData.append('file', file)
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .post(config.backendURL + '/images/banner', formData, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.reloadSettings()
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      onSubmit () {
      },
      saveSetting () {
        var currentJWT = localStorage.getItem(config.authToken)
        var component = this
        axios
          .put(config.backendURL + '/settings/bannerimages', component.bannerimages, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Impostazione banner salvata correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      reloadSettings () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .get(config.backendURL + '/settings/bannerimages', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              component.bannerimages = response.data.setting
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
    },
  }
</script>
