<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackbarColor"
    >
    {{ snackbarText }}
    </v-snackbar>
    <v-row
      justify="center"
    >
      <v-spacer />
      <v-col :cols="$isMobile()? 12 : 8">
        <v-card elevation="10">
          <v-card-title>Presentazione</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="presentationtext.text"
              auto-grow
              outlined
              placeholder="Testo"
              label="Testo"
            />
          </v-card-text>
          <v-card-actions>
            <v-row
              justify="end"
            >
              <v-spacer />
              <v-col cols="2">
                <v-btn
                  dark
                  @click="saveSetting"
                >
                SALVA
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
  const config = require('@/config')
  const axios = require('axios')
  export default {
    name: 'PresentationSettings',
    data: () => ({
      presentationtext: {
        text: '',
      },
      backendURL: config.backendURL,
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'primary',
    }),
    created: function () {
      this.reloadSettings()
    },
    methods: {
      saveSetting () {
        var currentJWT = localStorage.getItem(config.authToken)
        var component = this
        axios
          .put(config.backendURL + '/settings/presentation', component.presentationtext, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snackbarText = 'Presentazione salvata correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true
            } else {
              component.snackbarText = response.data.longMessage
              component.snackbarColor = 'error'
              component.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      reloadSettings () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .get(config.backendURL + '/settings/presentation', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              // console.log(component.presentationtext)
              component.presentationtext = response.data.setting
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
    },
  }
</script>
